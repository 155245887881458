<template>
  <div class="contailPanelV">
    <div class="intruduce">
      为贯彻落实《新一代人工智能发展规划》（国发〔2017〕35号）和《促进新一代人工智能产业发展三年行动计划（2018-2020年）》
      （工信部科〔2017〕315号）要求，建立面向人工智能基础技术及应用的检验检测基础服务平台和提供相关的检验检测,
      征集并遴选行业应用检测工具。
    </div>

     <search-item
          label="检测类型"
          :items="caseTypes"
          :currentValue="status1"
          @handleSearchItem="getByType">        
      </search-item>

    <page-list
              @pageChanged="handlePageChange"
              :detailPath="detailPath"
              :title="title"
              :list="patentlist"
              :current="pagination.current" 
              :size="pagination.size"
              :total="pagination.total"
    />
  </div>

</template>

<script>
  // import {getdicts} from '@/config/datadictionary'
import pageList from "@/components/list/pageTypeList"; //引入内容组件
import searchItem from '@/components/all/searchItem'
export default {
  name: "PatentList",  
  components: {
    pageList, //引入内容组件
    searchItem,
  },
  props: {
    // 当前的页码(2级菜单)
    chinum: {
      typeof: [Number],
      default: 0,
    },
  },
  data() {
    return {
      // checktypes: [],
      status1: '',

      caseTypes: [],
      primary: 1, //一级菜单的选中样式
      istrue: true, //刷新页面
      detailPath: '/inspect/industry/detail',
      patentlist: [], //当前数组
      title: '行业应用检验检测', //当前标题
      pagination: {
        current: 1, //当前页码
        size: 20, //每页条数
        total: 0, //总数
      },
    }
  },
  created() {
    // console.log(" created  pagination ", this.pagination)
    // this.gettypes();
    // console.log(" this. checktypes", this.checktypes)
    this.getCaseType();
    console.log(" this. case Type ", this.caseTypes)
    
  },
  
  mounted() {
    
    this.getlist(this.pagination)
  },

  methods: {
       //获取类型字典
      // gettypes(){
      //   getdicts(Object.assign({
      //     current: 1,
      //     size: 100,
      //     dataType: '行业类型',
      //   })).then(res => {
      //     this.checktypes.push({label: "全部", value: ''})
      //     for(let i = 0;i<res.data.data.records.length;i++){
      //         this.checktypes.push({label: res.data.data.records[i].dataName, value: res.data.data.records[i].ranking})
      //     }
      //   })
      // },
     getByType(type){
        this.status1 = type
        this.pageIndex = 1
        let param =  this.status1 ? {type: this.status1} : {}
        this.getlist(param)
      },
     //   改编页码之后
    handlePageChange(e) {
      // console.log("xxxx standard list page changed event ",  e)
      this.getlist(e);
    },
    getCaseType(){
      this.$api.getTestType().then((res) => {
        console.log("res ", res)
        let caseTypes =  [{label: "全部", value: ''}]
        res.data.records.forEach(item => {
          caseTypes.push( {label: item.dataName, value: item.dataName})
        })
        this.caseTypes = caseTypes
      })
    },
    // 获取当前数组
    getlist(pagination) {
      var data = {
        status: 1,
        ...pagination,
      };
      // console.log(" get list query ", data)
      this.$api.getInspectPage(data).then((res) => {
        // console.log("----------- get list result ", res)
        this.patentlist = res.data.records;
        // console.log("   before set pagination ", this.pagination )
        this.pagination.total = res.data.total;
        this.istrue=true
        // console.log("   after set pagination ", this.pagination )
      });
    },
  },
};
</script>

<style scoped lang="less">
 .search-panel{
    padding: 0 0.9375rem 0 0.9375rem;
    .search-text {
      width: 30rem;
      height: 2.1875rem;
      margin: 0.5rem 0;
    }
    .search-item {
      padding: 0.25rem 0;
    }
  }
  
@media screen and (min-width: 1000px) {
  .detailbox {
      min-height: 50vh;
    // 面包屑
    /deep/ .more {
      display: none;
    }
    /deep/ .cardbox {
      padding: 0;
      .cardhead {
        padding-left: 0.375rem;
      }
      .cardcent {
        margin-top: 1rem;
        padding-left: 0.375rem;

        .hangbox {
          height: 1.875rem;
          margin: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 999px) {
  .detailbox {
      min-height: 50vh;
    // 面包屑
    /deep/ .more {
      display: none;
    }
    /deep/ .cardbox {
      padding: 0 .9375rem;
      .cardhead {
        padding-left:.675rem;
      }
      .cardcent {
        .hangbox {
          height: 1.8rem;
          margin: 0;
        }
      }
    }
  }
}
</style>
